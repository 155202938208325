import * as React from "react"
import { Button, AnFadeIn } from "components"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { navigate } from "gatsby" //import navigate from gatsby
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "utils/linkResolver"
import {
  topImageWrapper,
  topImage,
  topImageText,
  yellSection,
  staffList,
  staffMember,
  bottomSection,
  staffMemberTop,
  staffMemberBottom,
} from "./styles.module.css"
import Imgix from "react-imgix"
import { Helmet } from "react-helmet"

const StaffPage = ({ data }) => {
  const { founders_image, body, subtitle, title } =
    data.allPrismicStaffPage.nodes[0].data

  const staff = data.allPrismicStaff.nodes
    .map(el => el.data)
    .sort((a, b) => {
      if (a.order < b.order) return -1
      if (a.order > b.order) return 1
      return 0
    })

  return (
    <main>
      <AnFadeIn>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Still Curious | Approach`}</title>
        </Helmet>
        <section className={yellSection}>
          <div className={topImageWrapper}>
            <Imgix
              src={founders_image.url}
              alt={founders_image.alt}
              className={topImage}
            />
            <h4 className={topImageText}>Founders Jo &amp; Bex</h4>
          </div>
          <h1>{title.text}</h1>
          <RichText render={body.raw} />
          <h2>{subtitle.text}</h2>
        </section>
        <ul className={staffList}>
          {staff.map(({ fun_image, name, position, profile_image }) => (
            <li key={profile_image.url} className={staffMember}>
              <div className={staffMemberTop}>
                <img width="800px" src={fun_image.url} />
                <h5>{position.text}</h5>
              </div>
              <div className={staffMemberBottom}>
                <img width="800px" src={profile_image.url} />
                <h5>
                  {name.text}, <br />
                  <span>{position.text}</span>
                </h5>
              </div>
            </li>
          ))}
        </ul>
        <section className={bottomSection}>
          <Button
            onClick={() => navigate("/contact")}
            title={"WANT TO WORK WITH US?"}
          />
        </section>
      </AnFadeIn>
    </main>
  )
}

export const query = graphql`
  query StaffPageQuery {
    allPrismicStaffPage {
      nodes {
        data {
          founders_image {
            alt
            url
          }
          body {
            raw
          }
          subtitle {
            text
          }
          title {
            text
          }
        }
      }
    }
    allPrismicStaff {
      nodes {
        data {
          fun_image {
            url
            alt
          }
          name {
            text
          }
          position {
            text
          }
          profile_image {
            url
          }
          order
        }
      }
    }
  }
`

export default withPrismicPreview(StaffPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
